<template>
  <div>
    <b-modal
      v-model="openModal"
      centered
      size="lg"
      :hide-footer="true"
      title="Detail Dispatch Rules"
    >
      <p>
        Detail
      </p>
    </b-modal>
    <b-card>
      <b-row>
        <b-col
          cols="6 mb-4"
        >
          <div class="px-2 py-1 navbar-light header-navbar rounded-lg shadow d-flex align-items-center justify-content-start">
            <feather-icon
              icon="SearchIcon"
              class="mr-50 text-primary"
              size="20"
            />
            <b-input-group class="input-group-merge shadow-none">
              <b-form-input
                v-model="code"
                placeholder="Rechercher un fichier..."
                style="height: auto;"
                class="border-0 shadow-none"
                @input="filter"
              />
            </b-input-group>
          </div>
        </b-col>
        <b-col
          cols="3"
        >
          <v-select
            v-model="shipper"
            :options="['Agediss', 'CAR', 'GommaGomma', 'Flexilan']"
            transition=""
            placeholder="Shipper"
            @input="filter"
          />
        </b-col>
        <b-col
          cols="3"
        >
          <flat-pickr
            v-model="rangeDate"
            placeholder="Date"
            class="form-control mr-1"
            @input="filter"
          />
        </b-col>
      </b-row>
      <div
        v-if="loading"
        class="d-flex justify-content-center mb-1"
      >
        <b-spinner label="Loading..." />
      </div>
      <b-row
        v-else
        class="match-height"
      >
        <b-table
          ref="selectableTable"
          selectable
          :items="dispatchs"
          :fields="fields"
        >
          <template #cell(date)="data">
            <span>
              {{ $moment(data.item.date).format('HH:mm / DD-MM-YYYY') }}
            </span>
          </template>
          <template #cell(file)="data">
            <span>
              <a :href="data.item.file">Download</a>
            </span>
          </template>
          <!-- <template #cell(disableError)="data">
            <feather-icon
              icon="TrashIcon"
              size="14"
              @click="dispatchs = dispatchs.filter((item) => item.number !== data.item.number)"
            />
          </template> -->
        </b-table>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BSpinner,
  BFormInput,
  BInputGroup,
  // BBadge,
  BTable,
  VBPopover,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { required } from '@validations'
import Ripple from 'vue-ripple-directive'
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'
import flatPickr from 'vue-flatpickr-component'

export default {
  components: {
    flatPickr,
    BFormInput,
    BCard,
    BSpinner,
    BRow,
    BCol,
    BTable,
    vSelect,
    // BBadge,
    BInputGroup,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContentVue,
  },
  directives: {
    'b-popover': VBPopover,
    Ripple,
  },
  data() {
    return {
      required,
      baseDispatchs: [],
      dispatchs: [],
      products: [],
      categories: [],
      logistic: {},
      rangeDate: null,
      shipper: null,
      conditionalProducts: '',
      conditionalCategories: '',
      types: [{ name: 'Remise fixe', type: 'fixed_cart' }, { name: 'Remise en pourcentage', type: 'percent' }],
      individualType: [{ name: 'Oui', type: true }, { name: 'Non', type: false }],
      conditions: [{ name: 'OU', type: 'or' }, { name: 'ET', type: 'and' }],
      code: '',
      fields: [{ key: 'date' }, { key: 'shipper' }, { key: 'file' }, { key: 'titleFile' }],
      showModal: false,
      categoryInformations: [],
      loading: false,
    }
  },
  async mounted() {
    try {
      await this.getDispatchs()
    } catch (err) {
      console.log(err)
    }
  },
  methods: {
    async getDispatchs() {
      try {
        const {
          dispatchs,
        } = await this.$http.get('/admin/logistic/files')
        this.dispatchs = dispatchs
        this.baseDispatchs = dispatchs
        this.loading = false
      } catch (err) {
        console.log(err)
      }
    },
    filter() {
      this.filterQuery = this.code
      if ((!this.code || this.code === '') && !this.shipper && !this.rangeDate) {
        this.dispatchs = this.baseDispatchs
      } else {
        const value = this.code
        this.dispatchs = this.baseDispatchs.filter(logistic => (logistic.titleFile?.toLowerCase().includes(value.toLowerCase())))
        if (this.shipper) {
          this.dispatchs = this.baseDispatchs.filter(logistic => this.shipper === logistic.shipper)
        }
        if (this.rangeDate) {
          this.dispatchs = this.baseDispatchs.filter(logistic => this.$moment(this.rangeDate).isSame(this.$moment(logistic.date), 'days'))
        }
      }
    },
  },
}
</script>

<style>
 .jsoneditor-poweredBy {
   display: none;
 }
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
